import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { WEB3_STATE } from '@/constants/constants';

export const store = new Vuex.Store({
    state: () => ({
        address: null,
        chainId: null,
        provider: null,
        signer: null,

        web3State: WEB3_STATE.CONNECT,
    }),

    getters: {
        address (state) {
            return state.address;
        },
        chainId (state) {
            return state.chainId;
        },
        provider (state) {
            return state.provider;
        },
        signer (state) {
            return state.signer;
        },
        web3State (state) {
            return state.web3State;
        },
    },
    
    mutations: {
        updateAddress (state, address) {
            state.address = address;
        },
        updateChainId (state, chainId) {
            state.chainId = chainId;
        },
        updateProvider (state, provider) {
            state.provider = provider;
        },
        updateSigner (state, signer) {
            state.signer = signer;
        },

        updateWeb3State (state, web3State) {
            state.web3State = web3State;
        },
    },
});