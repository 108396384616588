exports.FAQs = Object.freeze([
    {
        q: "When are PsychoPunks dropping?",
        a: "Tuesday, August 31st @10AM PST."
    },
    {
        q: "How much will it cost to mint one PsychoPunk?",
        a: "0.03ETH + Gas."
    },
    {
        q: "How many PsychoPunks are there?",
        a: "2,000."
    },
    {
        q: "Do I need a Twitter to mint?",
        a: "Absolutely not. Just leave that part blank and I’ll mint you a random one straight from my heart.\n\nAnd yes, you can still mint the rare ones."
    },
    {
        q: "Who built this atrocity?",
        a: "Sup. My name is Soma.\n\nI like to hang out where art and tech collide."
    },
    {
        q: "Why did you build it?",
        a: "It started with the idea to have people's personality influence the art they mint. To make generative art with the community's dna. To make things more personal.\n\nEnter PsychoPunks."
    },
]);