<template>
  <div class="wrapper">
    <div class="transition">
      <div v-for="(triangle, i) in transitionTriangles" :key="i" class="triangle-down" :class="{ 'triangle-down-first': i == 0 }" />
    </div>
    <div class="wrapper-column">
      <div class="header">
        <p class="h-logo">FAQ</p>
      </div>
      <div class="body" :class="$mq">
        <div class="faq" v-for="faq in FAQs" :key="faq.q">
          <p class="faq-question">{{faq.q}}</p>
          <p class="faq-answer">{{faq.a}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FAQs } from '@/constants/FAQs';

export default {
  name: 'FAQ',
  mounted() {
    window.addEventListener('resize', () => { this.windowWidth = window.innerWidth });
  },
  data() { return { windowWidth: window.innerWidth } },
  computed: {
    FAQs() {
      return FAQs;
    },

    transitionTriangles() {
      let numTriangles = Math.ceil(this.windowWidth / 64);
      return new Array(numTriangles + 1);
    }
  },
}
</script>

<style scoped>

/* Wrappers */
.wrapper {
  width: 100%;

  background-color: var(--y1);

  display: flex;
  justify-content: center;

  position: relative;
}

.wrapper-column {
  max-width: 1124px;
  width: 100%;
  height: 100%;
  padding: 0px 24px;

  display: flex;
  flex-direction: column;
}

.triangle-down {
  border-bottom: 24px solid var(--y1);
}

/* Header */
.header {
  width: 100%;
  margin-top: 72px;
  text-align: left;
}

.h-logo {
  font-size: 56px;
  text-align: left;
  
  flex: 1;
  display: inline-block;
  position: relative;
}
.h-logo::after {
  content: '';
  left: 0;
  right: 0;
  bottom: -12px;
  border: 1px solid black;
  position: absolute;
}

/* Body */
.body {
  width: 100%;
  margin-top: 48px;
  margin-bottom: 88px;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.body.mobile {
  margin-bottom: 48px;
}

.faq {
  width: 100%;
  padding: 24px 32px 24px 32px;
  margin-bottom: 32px;
  box-sizing: border-box;

  text-align: left;
  background-color: var(--y2);
}

.faq-question {
  font-size: 38px;
  font-weight: normal;
  margin-bottom: 18px;

  display: inline-block;
  position: relative;
}
.faq-question::after {
  content: '';
  left: 0;
  right: 0;
  bottom: -6px;
  border: 1px solid black;
  position: absolute;
}

.faq-answer {
  font-size: 30px;
  white-space: pre-wrap;
}

</style>
