<script>
import { ethers } from 'ethers';
import { CORRECT_NETWORK, TOTAL_FOR_SALE, WEB3_STATE } from '@/constants/constants';
import { address as CONTRACT_ADDRESS } from '@/assets/contract/address.json';
import { abi as CONTRACT_ABI } from '@/assets/contract/abi.json';

export default {
	name: "Metamask",
	render: () => null,
	mounted() {
		this.initMetamask();
	},
	computed: {
		address() {
			return this.$store.getters.address;
		},
		provider() {
			return this.$store.getters.provider;
		},
		signer() {
			return this.$store.getters.signer;
		},
		chainId() {
			return this.$store.getters.chainId;
		},
	},
	methods: {
		async initMetamask() {
			// Get provider
			const ethereum = window.ethereum;
			if (ethereum == null) {
				this.$store.commit("updateWeb3State", WEB3_STATE.INSTALL);
				return;
			}

			const provider = new ethers.providers.Web3Provider(ethereum);
			this.$store.commit("updateProvider", provider);

			if (provider) {
				// Get MetaMask account if connected
				ethereum.request({ method: "eth_accounts" }).then(this.accountsChanged);

				// Get signer
				const signer = provider.getSigner();
				this.$store.commit("updateSigner", signer);

				// Detect account changes
				ethereum.on("accountsChanged", this.accountsChanged);

				// Detect chain changes
				ethereum.on("chainChanged", this.chainChanged);
			} else {
				this.$store.commit("updateWeb3State", WEB3_STATE.INSTALL);
			}
		},
		connectWallet() {
			window.ethereum.request({ method: "eth_requestAccounts" });
		},
		accountsChanged(accounts) {
			const address = accounts[0];
			this.$store.commit("updateAddress", address);
			this.setWeb3State();
		},
		chainChanged(chainId) {
			window.location.reload();
		},
		async setWeb3State() {
			if (this.provider != null && this.signer != null && this.address != null) {
				// Web3 connected, check chain id
				if (this.chainId == null) {
					this.$store.commit("updateChainId", window.ethereum.networkVersion);
				}

				if (this.chainId == CORRECT_NETWORK) {
					// Correct network, check if contract is live
					if (CONTRACT_ADDRESS == null || CONTRACT_ABI == null) {
						this.$store.commit("updateWeb3State", WEB3_STATE.SALE_INACTIVE);
						return;
					}

					// Correct network, check sale status
					let contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, this.provider);
					const saleIsActive = await contract.saleIsActive();

					if (saleIsActive) {
						// Sale is active, check if sold out
						const totalSupply = await contract.totalSupply();

						if (totalSupply.toNumber() >= 1980) {
							this.$store.commit("updateWeb3State", WEB3_STATE.SOLD_OUT);
						} else {
							this.$store.commit("updateWeb3State", WEB3_STATE.SALE_ACTIVE);
						}
					} else {
						this.$store.commit("updateWeb3State", WEB3_STATE.SALE_INACTIVE);
					}
				} else {
					this.$store.commit("updateWeb3State", WEB3_STATE.WRONG_NETWORK);
				}
			} else {
				this.$store.commit("updateWeb3State", WEB3_STATE.CONNECT);
			}
		},
	},
};
</script>
