<script>
import { ethers } from 'ethers';
import { WEB3_STATE } from '@/constants/constants';
import { address as CONTRACT_ADDRESS } from '@/assets/contract/address.json';
import { abi as CONTRACT_ABI} from '@/assets/contract/abi.json';

export default {
  name: 'Contract',
  methods: {
    async initContract() {
      try {
        if (CONTRACT_ADDRESS == null || CONTRACT_ABI == null) { return; }

        this.contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, this.signer);

        let contractProperties = [this.contract.totalSupply(), this.contract.MAX_SUPPLY(), this.contract.MAX_PURCHASE(), this.contract.getPrice()];
        let propertyNames = ['AMOUNT_MINTED', 'MAX_SUPPLY', 'MAX_PURCHASE', 'PRICE'];

        await Promise.all(contractProperties.map(async (promise, i) => {
          this[propertyNames[i]] = await promise;
        }));

        this.AMOUNT_MINTED = this.AMOUNT_MINTED.toNumber();
        this.MAX_SUPPLY = this.MAX_SUPPLY.toNumber();
        this.MAX_PURCHASE = this.MAX_PURCHASE.toNumber();

        this.contractReady = true;
      } catch (err) {
        // console.error(err);
      }
    },
  },
  data() {
    return {
      // Contract
      contract: null,
      contractReady: false,

      PRICE: null,
      AMOUNT_MINTED: null,
      MAX_SUPPLY: null,
      MAX_PURCHASE: 0,
    }
  },
  computed: {
    // Web3
    web3State() {
        return this.$store.getters.web3State;
    },
    signer() {
        return this.$store.getters.signer;
    },
    PRICE_IN_ETHER() {
        return this.PRICE ? ethers.utils.formatEther(this.PRICE) : '0.00';
    },

    // Constants
    WEB3_STATE() {
        return WEB3_STATE
    },
  },
  watch: {
    web3State: {
      handler() {
        if (this.web3State == WEB3_STATE.SALE_ACTIVE || this.web3State == WEB3_STATE.SALE_INACTIVE) {
          this.initContract();
        } else {
          this.contractReady = false;
        }
      },
      immediate: true
    },
  },
}
</script>