// URLs
exports.TWITTER_URL = "https://twitter.com/psychopunksnft"
exports.DISCORD_URL = "https://discord.gg/WbKfCWwFpk"
exports.INSTALL_METAMASK_URL = "https://metamask.io/download.html"
exports.OPENSEA_URL = "https://opensea.io/collection/psychopunks-nft"
exports.ETHERSCAN_BASE_URL = "https://etherscan.io"

// Web3
exports.CORRECT_NETWORK = 1;
exports.TOTAL_FOR_SALE = 2000;
exports.WEB3_STATE = Object.freeze({
    INSTALL: 0,
    CONNECT: 1,
    WRONG_NETWORK: 2,
    SALE_INACTIVE: 3,
    SALE_ACTIVE: 4,
    SOLD_OUT: 5
});

// Minting
exports.MINT_STATE = Object.freeze({
    READY: 0,
    WAITING: 1,
    SUCCESS: 2
})