import Vue from 'vue'
import App from './App.vue'

// Store
import { store } from './store'

// Event Bus
import { eventBus } from './eventBus'
Vue.prototype.$bus = eventBus

// VueMQ
import VueMq from 'vue-mq'
Vue.use(VueMq, {
  breakpoints: {
    mobile: 900,
    default: Infinity,
  }
})

// Misc
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
}).$mount('#app')
