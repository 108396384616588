<template>
  <div>
    <Home />
    <Metamask />
  </div>
</template>

<script>
import { Home, Metamask } from './components'

export default {
  name: 'App',
  components: {
    Home,
    Metamask
  }
}
</script>

<style>
#app, body {
  margin: 0;
  box-sizing: border-box;

  text-align: center;
  font-family: "m5x7";
  font-size: 24px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
  line-height: 28px;
  text-align: left;
}

/* Colors */
:root {
  --wh: #FFFFFF;
  --bl: #000000;
  --gr1: #F6F7F9;
  --gr2: #E6ECF1;
  --r1: #FF2573;
  --r2: #FF5F98;
  --r3: #FF1367;
  --r4: #FF5F98;
  --r5: #E91862;
  --y1: #FFDB1F;
  --y2: #FFF38A;
  --y3: #FFE81F;
  --b1: #02ECD0;
  --b2: #16FDE4;
  --b3: #8CADFF;
  --b4: #8CDCFF;
  --b5: #007C68;
  --b6: #28253B;
  --g1: #22C09C;
  --g2: #00E771;
  --g3: #00FFC4;
  --p1: #BA47ED;
  --p2: #8C44E7;
}

/* Headings */
/* h1 {
  font-size: 32px;
  font-weight: 800;
}

h2 {
  font-size: 24px;
  font-weight: 800;
}

h3 {
  font-size: 18px;
  font-weight: 800;
} */

/* h1, h2, h3, h4, h5 {
  color: #ffffff;
  margin: 0;
} */

/* Buttons */
.button {
  border-radius: 8px;
  background-color: var(--bl);

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
  cursor: pointer;
}

/* Shapes */
.transition {
  top: -24px;
  width: 100%;

  display: flex;
  overflow: hidden;
  position: absolute;
}

.triangle-down {
  top: -32px;
  width: 0; 
  height: 0; 
  border-left: 32px solid transparent;
  border-right: 32px solid transparent;
}

.triangle-down-first {
  border-left: 0px;
}

a {
  color: black;
  text-decoration: none;
}

/* Fonts */
@font-face {
  font-family: "m5x7";
  src: url("~@/assets/fonts/m5x7.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
