<template>
  <div class="wrapper" v-if="showMint">
    <div class="modal">
      <img class="exit" srcset="@/assets/images/xBlack.png 3x" @click="closeMintModal" />
      <div v-if="mintState == MINT_STATE.READY">
        <div class="modal-title-mint">MINT</div>
        <div class="divider divider-1"/>
        <div class="w-twitter">
          <div class="w-title">
            <p v-if="validUsername == true">TWITTER USERNAME</p>
            <p style="font-size: 28px;" v-if="validUsername == true">(not required)</p>
            <div class="t-error" v-if="validUsername == false">INVALID USERNAME</div>
          </div>
          <div class="w-twitter-input">
            <div class="t-twitter-at">@</div>
            <input class="i-twitter-username"
                  v-model="twitterUsername"
                  spellcheck="false" />
          </div>
        </div>
        <div class="w-amount">
          <div class="w-title">
            <p v-if="!this.contractReady || mintAmount <= MAX_PURCHASE">AMOUNT</p>
            <div v-if="!this.contractReady || mintAmount <= MAX_PURCHASE" style="font-size: 28px;"><span>{{ PRICE_IN_ETHER }}</span><span style="font-size: 17px">Ξ</span><span> each</span></div>
            <p v-if="this.contractReady && mintAmount > MAX_PURCHASE" class="t-error">CAN ONLY MINT {{ MAX_PURCHASE }} AT A TIME.</p>
          </div>
          <div class="w-amount-input">
            <div class="button b-mint-amount" @click="mintAmount--;">-</div>
            <input class="i-mint-amount"
                  placeholder="0"
                  type="number"
                  @focus="$event.target.select()"
                  v-model="mintAmount" />
            <div class="button b-mint-amount" @click="mintAmount++;">+</div>
          </div>
        </div>
        <div class="divider divider-2"/>
        <div class="w-mint">
          <div class="button b-mint" :class="{'b-mint-disabled': !isInputValid}" @click="mint">MINT</div>
        </div>
      </div>
      <div v-if="mintState == MINT_STATE.WAITING">
        <div class="w-mint">
          <div class="button b-mint b-mint-awaiting-confirmation">WAITING FOR CONFIRMATION...</div>
        </div>
      </div>
      <div v-if="mintState == MINT_STATE.SUCCESS">
        <div class="modal-title-success">TRANSACTION SUBMITTED!</div>
        <a class="view-on-etherscan" v-bind:href="ETHERSCAN_TX_URL" target="_blank" tabindex="-1">VIEW ON ETHERSCAN</a>
        <div class="divider divider-3"/>
        <div class="w-mint">
          <div class="button b-mint b-mint-opensea" @click="opensea">VIEW ON OPENSEA<img class="i-opensea" srcset="@/assets/images/openseaWhite.png 3x" /></div>
          <div class="button b-mint b-mint-more" @click="mintState = MINT_STATE.READY">MINT MORE</div>
          <div class="button b-mint b-mint-close" @click="closeMintModal">CLOSE</div>
        </div>
      </div>
    </div>
    <div class="outside" @click="closeMintModal"></div>
  </div>
</template>

<script>
import { Contract } from '@/components/mixins';
import { ETHERSCAN_BASE_URL, OPENSEA_URL, MINT_STATE } from '@/constants/constants';

export default {
  name: 'Mint',
  mixins: [
    Contract
  ],
  mounted() {
    this.$bus.$on('openMintModal', this.openMintModal);
    this.$bus.$on('closeMintModal', this.closeMintModal);
  },
  beforeDestroy() {
    this.$bus.$off('showMintModal', this.openMintModal);
    this.$bus.$off('closeMintModal', this.closeMintModal);
  },
  methods: {
    openMintModal() {
      document.documentElement.style.overflow = 'hidden';

      // Reset state
      this.mintAmount = 1;
      this.mintState = MINT_STATE.READY;
      this.showMint = true;
    },
    closeMintModal() {
      document.documentElement.style.overflow = 'auto';
      this.showMint = false;
    },

    // Minting
    async mint() {
      try {
        if (!this.contractReady || this.mintAmount == null) { return }
        this.mintAmount = parseInt(this.mintAmount);
        this.mintState = MINT_STATE.WAITING;

        // Get amount of ether needed
        const value = this.PRICE.mul(this.mintAmount);

        // Set gas limit
        let gasLimit = await this.contract.connect(this.signer).estimateGas.mint(this.mintAmount, this.twitterUsername, { value });
        // console.log('gasEstimate:', gasLimit.toNumber());
        gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through
        // console.log('gasLimit:', gasLimit.toNumber());

        // Mint
        // console.log(`minting ${this.mintAmount} PsyhcoPunks...`);
        const tx = await this.contract.connect(this.signer).mint(this.mintAmount, this.twitterUsername, { value, gasLimit });
        this.txHash = tx.hash;
        this.mintState = MINT_STATE.SUCCESS;
        // console.log(`minted ${this.mintAmount} PsychoPunks!`);
      } catch (err) {
        this.mintState = MINT_STATE.READY;
        // console.error(err);
      }
    },

    // Socials
    opensea() {
      window.open(OPENSEA_URL, '_blank').focus();
    },
  },
  data() {
    return {
      // Input
      mintAmount: 1,
      twitterUsername: "",

      // Minting
      validUsername: true,
      txHash: null,

      // State
      showMint: false,
      mintState: MINT_STATE.READY,
    }
  },
  computed: {
    MINT_STATE() {
      return MINT_STATE;
    },
    ETHERSCAN_TX_URL() {
      return `${ETHERSCAN_BASE_URL}/tx/${this.txHash}`;
    },

    // Input sanitization
    isInputValid() {
      return (this.contractReady && this.mintAmount != '' && this.mintAmount > 0 && this.mintAmount <= this.MAX_PURCHASE && this.validUsername);
    },
  },
  watch: {
    // Input sanitization
    mintAmount: {
      handler() { if (this.mintAmount < 0) { this.mintAmount = 0; } },
      immediate: true
    },
    twitterUsername: {
      handler() {
        // Check if valid username
        const twitterUsernameRegex = /^(\w){1,15}$/;
        if (!this.twitterUsername || twitterUsernameRegex.test(this.twitterUsername)) {
            this.validUsername = true;
        } else {
            this.validUsername = false;
        }
      },
      immediate: true
    },
  },
}
</script>

<style scoped>

/* Wrappers */
.wrapper {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
}

.modal {
  width: 400px;
  padding: 20px 24px 24px 24px;

  border-radius: 12px;
  background-color: white;

  font-size: 32px;

  display: flex;
  flex-direction: column;

  position: relative;
}

.exit {
  top: 28px;
  right: 24px;
  position: absolute;
  cursor: pointer;
}

.outside {
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: -1;
}

.modal-title-mint {
  margin-bottom: 16px;
}

.modal-title-success {
  margin-bottom: 4px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--gr2);
}

.divider-1 {
  margin-bottom: 24px;
}

.divider-2 {
  margin-bottom: 28px;
}

.divider-3 {
  margin-bottom: 16px;
}

.w-title {
  width: 100%;
  margin-bottom: 12px;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.t-error {
  width: 100%;
  text-align: center;
  color: var(--r1);
}

/* Twitter */
.w-twitter {
  width: 100%;
  margin-bottom: 24px;
}

.w-twitter-input {
  width: 100%;
  height: 72px;

  border-radius: 8px;
  background-color: var(--gr1);

  display: flex;
  overflow: hidden;
}

.t-twitter-at {
  width: 72px;
  height: 100%;
  padding-bottom: 4px;

  font-size: 46px;
  background-color: var(--gr2);

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}

.i-twitter-username {
  flex: 1;
  min-width: 0;
  margin: 0px 16px;
  padding: 0;

  font-family: "m5x7";
  font-size: 32px;
  text-align: left;

  background: transparent;

  box-sizing: border-box;
  outline: none;
  border: none;

  text-indent: 1px;
}
.i-mint-amount::placeholder {
  color: black;
  opacity: 0.5;
}

/* Amount */
.w-amount {
  width: 100%;
  margin-bottom: 28px;
}

.w-amount-input {
  width: 100%;
  border-radius: 8px;
  display: flex;
}

.b-mint-amount {
  width: 90px;
  height: 90px;

  font-size: 48px;
  background-color: var(--gr2);
}

.i-mint-amount {
  flex: 1;
  min-width: 0;
  margin: 0px 8px;
  padding: 0;

  font-family: "m5x7";
  font-size: 46px;
  text-align: center;

  border-radius: 8px;
  background: var(--gr1);

  box-sizing: border-box;
  outline: none;
  border: none;
}
.i-mint-amount::placeholder {
  color: black;
  opacity: 0.5;
}

/* Mint Button */
.w-mint {
  width: 100%;
}

.b-mint {
  width: 100%;
  height: 90px;

  color: black;
  background-color: var(--g3);

  font-size: 44px;
}

.b-mint-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.b-mint-awaiting-confirmation {
  font-size: 32px;
  background-color: var(--wh);
  pointer-events: none;
}

.b-mint-more {
  font-size: 36px;
  margin-bottom: 16px;
}

.b-mint-opensea {
  color: white;
  font-size: 36px;
  background-color: #4C90E1;
  margin-bottom: 16px;

  position: relative;
}

.i-opensea {
  width: 42px;
  height: 42px;

  right: 24px;

  position: absolute;
}

.b-mint-close {
  font-size: 36px;
  background-color: var(--gr2);
}

.view-on-etherscan {
  margin-bottom: 16px;
  font-size: 24px;
  color: #4C90E1;
  display: inline-block;
}

</style>
