<template>
  <div class="wrapper" id="how-it-works">
    <div class="transition">
      <div v-for="(triangle, i) in transitionTriangles" :key="i" class="triangle-down" :class="{ 'triangle-down-first': i == 0 }" />
    </div>
    <div class="wrapper-column">
      <div class="header" :class="$mq">
        <p class="h-logo">HOW IT WORKS</p>
      </div>
      <div class="body" :class="$mq">
        <div class="section section-1" :class="$mq">
          <p class="s-1-text">The average sentiment of your tweets determines the color of the traits you mint:</p>
          <img class="s-1-rainbow" srcset="@/assets/images/sentimentRainbow.png 3x" />
          <div class="s-1-axis">
            <div class="s-1-axis-text-left"><span class="s-1-negative">negative</span><span> tweet sentiment</span></div>
            <div class="s-1-axis-text-right"><span class="s-1-positive">positive</span><span> tweet sentiment</span></div>
          </div>
        </div>
        <div class="s-divider" />
        <div class="section section-2">
          <p class="s-2-text">How opinionated your tweets are determines your pyscho score:</p>
          <div class="s-2-graphs" :class="$mq">
            <div>
              <img class="s-2-graph s-2-graph-left" srcset="@/assets/images/lowPsychoScore.png 3x" />
              <p class="s-2-graph-text">low psycho score</p>
            </div>
            <div>
              <img class="s-2-graph s-2-graph-right" srcset="@/assets/images/highPsychoScore.png 3x" />
              <p class="s-2-graph-text">high psycho score</p>
            </div>
          </div>
          <div class="s-2-box">
            <p>The higher your psycho score, the higher your chance to mint a rare PsychoPunk.</p>
          </div>
        </div>
        <div class="section section-3">
          <div class="header" :class="$mq">
            <p class="h-logo">GALLERY</p>
          </div>
          <div class="s-3-gallery" :style="{
            'backgroundImage': `url('${require('@/assets/images/gallery.png')}')`,
            'background-position-x': `${galleryPositionX}px`
          }" />
          <div class="s-3-gradient" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks',
  mounted() {
    window.addEventListener('resize', () => { this.windowWidth = window.innerWidth });
    this.animateGallery();
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      galleryPositionX: 0,
    }
  },
  computed: {
    transitionTriangles() {
      let numTriangles = Math.ceil(this.windowWidth / 64);
      return new Array(numTriangles + 1);
    }
  },
  methods: {
    async animateGallery() {
      this.galleryPositionX--;
      setTimeout(this.animateGallery, 20);
    },
  }
}
</script>

<style scoped>

/* Wrappers */
.wrapper {
  width: 100%;

  background-color: var(--bl);

  display: flex;
  justify-content: center;

  position: relative;
}

.wrapper-column {
  max-width: 1124px;
  width: 100%;
  height: 100%;
  padding: 0px 24px;

  display: flex;
  flex-direction: column;
}

.triangle-down {
  border-bottom: 24px solid var(--bl);
}

/* Header */
.header {
  width: 100%;
  margin-top: 72px;
  text-align: left;
}
.header.mobile {
  text-align: center;
}

.h-logo {
  font-size: 56px;
  text-align: left;
  
  color: var(--wh);

  flex: 1;
  display: inline-block;
  position: relative;
}
.h-logo::after {
  content: '';
  left: 0;
  right: 0;
  bottom: -12px;
  border: 1px solid white;
  position: absolute;
}

/* Body */
.body {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  max-width: 760px;

  color: var(--wh);
  font-size: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-1 {
  margin-top: 72px;
  margin-bottom: 56px;
}
.section-1.mobile {
  margin-top: 48px;
}

.section-2 {
  margin-top: 56px;
}

.section-3 {
  width: 100%;
  max-width: none;
  height: 740px;
  margin-top: 32px;

  justify-content: flex-start;
}

.s-divider {
  width: 100%;
  height: 1px;
  background-color: var(--b6);
}

/* Section 1 */
.s-1-text {
  width: 100%;
  max-width: 480px;
  margin-bottom: 32px;
  text-align: center;
}

.s-1-rainbow {
  width: 100%;
}

.s-1-axis {
  width: 100%;
  margin-top: 32px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.s-1-axis-text-left {
  text-align: left;
  margin-right: 12px;
}

.s-1-axis-text-right {
  text-align: right;
  margin-left: 12px;
}

.s-1-negative {
  color: var(--r3);
}

.s-1-positive {
  color: var(--g2);
}

/* Section 2 */
.s-2-text {
  width: 100%;
  max-width: 360px;
  margin-bottom: 48px;
  text-align: center;
}

.s-2-graphs {
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.s-2-graphs > div {
  margin-bottom: 48px;
}

.s-2-graphs.mobile {
  justify-content: center;
}
.s-2-graphs.mobile > div {
  flex-grow: 1;
}

.s-2-graph {
  width: 100%;
  max-width: 316px;
}
.s-2-graph-left {
  margin-right: 2px;
}
.s-2-graph-right {
  margin-left: 2px;
}

.s-2-graph-text {
  text-align: center;
}

.s-2-box {
  width: 100%;
  padding: 24px 24px;
  box-sizing: border-box;

  font-size: 26px;
  background-color: var(--b6);

  display: flex;
  justify-content: center;
  align-items: center;
}

/* Section 3 */
.s-3-gallery {
  width: 100%;
  height: 600px;
  margin-top: 140px;
  
  background-size: cover;
  background-repeat: repeat;

  position: absolute;
}

.s-3-gradient {
  width: 100%;
  height: 200px;
  margin-top: 540px;

  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));

  position: absolute;
}

</style>
