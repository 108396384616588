<template>
  <div class="home-wrapper">
    <Landing />
    <HowItWorks />
    <FAQ />
    <ContractAddress />

    <Mint />
  </div>
</template>

<script>
import { Landing, HowItWorks, Features, FAQ, ContractAddress } from './pages'
import { Mint } from './modals'

export default {
  name: 'Home',
  components: {
    // Pages
    Landing,
    HowItWorks,
    FAQ,
    ContractAddress,

    // Modals
    Mint,
  },
}
</script>

<style scoped>

.home-wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
}

</style>
