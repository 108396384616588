<template>
  <div class="wrapper">
    <div class="transition">
      <div v-for="(triangle, i) in transitionTriangles" :key="i" class="triangle-down" :class="{ 'triangle-down-first': i == 0 }" />
    </div>
    <div class="wrapper-column">
      <div class="body" v-if="CONTRACT_ADDRESS">
        <p class="t-contract-address">CONTRACT ADDRESS</p>
        <div class="w-contract-address">
          <a class="contract-address" v-bind:href="ETHERSCAN_CONTRACT_URL" target="_blank" tabindex="-1">{{ CONTRACT_ADDRESS }}</a>
        </div>
      </div>
      <div class="footer" :class="$mq">
        <img class="f-logo" srcset="@/assets/images/psychoPunksLogoBlack.png 3x" />
        <div class="w-socials">
          <div class="button b-twitter" @click="twitter"><img srcset="@/assets/images/twitterBlack.png 3x" /></div>
          <div class="button b-discord" @click="discord"><img srcset="@/assets/images/discordBlack.png 3x" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TWITTER_URL, DISCORD_URL, ETHERSCAN_BASE_URL } from '@/constants/constants';
import { address as CONTRACT_ADDRESS } from '@/assets/contract/address.json';

export default {
  name: 'ContractAddress',
  mounted() {
    window.addEventListener('resize', () => { this.windowWidth = window.innerWidth });
  },
  methods: {
    // Socials
    twitter() {
      window.open(TWITTER_URL, '_blank').focus();
    },
    discord() {
      window.open(DISCORD_URL, '_blank').focus();
    },
  },
  data() { return { windowWidth: window.innerWidth } },
  computed: {
    CONTRACT_ADDRESS() {
      return CONTRACT_ADDRESS;
    },
    ETHERSCAN_CONTRACT_URL() {
      return `${ETHERSCAN_BASE_URL}/address/${CONTRACT_ADDRESS}`;
    },

    transitionTriangles() {
      let numTriangles = Math.ceil(this.windowWidth / 64);
      return new Array(numTriangles + 1);
    }
  },
}
</script>

<style scoped>

/* Wrappers */
.wrapper {
  width: 100%;

  background-color: var(--b2);

  display: flex;
  justify-content: center;

  position: relative;
}

.wrapper-column {
  max-width: 1124px;
  width: 100%;
  height: 100%;
  padding: 0px 24px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  box-sizing: border-box;
}

.triangle-down {
  border-bottom: 24px solid var(--b2);
}

/* Footer */
.footer {
  width: 100%;
  margin-top: 56px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer.mobile {
  justify-content: space-around;
}

.f-logo {
  margin: 0px 0px 24px 0px;

  font-size: 56px;
  text-align: center;
}

.w-socials {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

.b-twitter {
  width: 64px;
  height: 64px;

  padding: 14px;
  box-sizing: border-box;

  background-color: var(--b1);
}

.b-discord {
  width: 64px;
  height: 64px;

  padding: 14px;
  margin-left: 16px;
  box-sizing: border-box;

  background-color: var(--b1);
}

/* Body */
.body {
  width: 100%;
  margin-top: 120px;
  margin-bottom: 48px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.t-contract-address {
  font-size: 56px;
  color: var(--b5);

  text-align: center;
  text-overflow: wrap;
  line-height: 36px;
}

.w-contract-address {
  width: 100%;
  max-width: 688px;

  padding: 24px 48px;
  margin-top: 32px;
  box-sizing: border-box;

  font-size: 38px;
  color: var(--bl);
  background-color: var(--y3);
}

.contract-address {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

</style>
