<template>
  <div class="wrapper">
    <div class="wrapper-column">
      <div class="header" :class="$mq">
        <img class="h-logo" srcset="@/assets/images/psychoPunksLogoWhite.png 3x" />
        <div class="w-socials">
          <div class="button b-twitter" @click="twitter"><img srcset="@/assets/images/twitterWhite.png 3x" /></div>
          <div class="button b-discord" @click="discord"><img srcset="@/assets/images/discordWhite.png 3x" /></div>
        </div>
        <div class="h-divider" />
      </div>
      <div class="body" :class="$mq">
        <div class="body-column-1" :class="$mq">
          <div class="row-1-c-1" :class="$mq">
            <p>Hey, so I made this thing called PsychoPunks.</p>
            <div class="body-section-highlighted">
              <p>It psychoanalyzes your Twitter feed with an AI algo, then creates a PsychoPunk based on your personality.</p>
            </div>
            <p>What do you DO with it? Print it out and put it on your fridge. Maybe tattoo it on your rib cage. I don’t really care. All I know is you’ll love it.<br><br>Try it. Get hooked. Tell your friends about it. Confuse your mom. I know she can’t understand why you waste your money on this nonsense. It's alright. Do it anyways.</p>
          </div>
          <div class="row-2">
            <div class="button b-how-it-works" @click="scrollToHowItWorks" :class="$mq">How It Works<img class="i-how-it-works-arrow" srcset="@/assets/images/arrowDownWhite.png 3x" /></div>
          </div>
        </div>
        <div class="body-column-2" :class="$mq">
          <div class="row-1-c-2" :class="$mq">
            <img class="example-images" src="@/assets/images/psychopunksGif.gif" />
          </div>
          <div class="row-2">
            <div class="button b-mint" v-if="web3State == WEB3_STATE.INSTALL" @click="install"><p>INSTALL</p></div>
            <div class="button b-mint" v-if="web3State == WEB3_STATE.CONNECT" @click="connect"><p>CONNECT</p></div>
            <div class="button b-mint b-mint-wrong-network" v-if="web3State == WEB3_STATE.WRONG_NETWORK"><p>WRONG NETWORK</p></div>
            <div class="button b-mint b-mint-sale-inactive" v-if="web3State == WEB3_STATE.SALE_INACTIVE"><p style="text-align: center;">SALE STARTS: AUG 31 @10AM PST</p></div>
            <div class="button b-mint" v-if="web3State == WEB3_STATE.SALE_ACTIVE" @click="mint"><p>MINT</p></div>
            <div class="button b-mint" v-if="web3State == WEB3_STATE.SOLD_OUT" @click="opensea"><p>BUY 0N OPENSEA</p></div>
          </div>
          <div class="row-3" v-if="AMOUNT_MINTED != null && MAX_SUPPLY != null && PRICE_IN_ETHER != null">
            <p>{{ AMOUNT_MINTED }} / {{ MAX_SUPPLY }} minted</p>
            <div style="margin-top: 8px;">
              <span>{{ PRICE_IN_ETHER }}</span><span style="font-size: 20px">Ξ</span><span> each</span>
            </div>
          </div>
          <div class="row-3" v-if="web3State == WEB3_STATE.SOLD_OUT">
            <p>SOLD OUT!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Contract } from '@/components/mixins';
import { TWITTER_URL, DISCORD_URL, INSTALL_METAMASK_URL, OPENSEA_URL } from '@/constants/constants';

export default {
  name: 'Landing',
  mixins: [
    Contract
  ],
  methods: {
    // Socials
    twitter() {
      window.open(TWITTER_URL, '_blank').focus();
    },
    discord() {
      window.open(DISCORD_URL, '_blank').focus();
    },

    // Minting
    install() {
      window.open(INSTALL_METAMASK_URL, '_blank').focus();
    },
    connect() {
      window.ethereum.request({ method: "eth_requestAccounts" });
    },
    mint() {
      this.$bus.$emit('openMintModal');
    },
    opensea() {
      window.open(OPENSEA_URL, '_blank').focus();
    },

    // Misc
    scrollToHowItWorks() {
      const element = document.getElementById('how-it-works');
      const y = element.getBoundingClientRect().top + window.pageYOffset + 80;
      window.scrollTo({ top: y, behavior: 'smooth' });
    },
  },
}
</script>

<style scoped>

/* Wrappers */
.wrapper {
  width: 100%;

  color: white;
  background-color: var(--r1);

  display: flex;
  justify-content: center;
}

.wrapper-column {
  max-width: 1124px;
  width: 100%;
  height: 100%;
  padding: 0px 24px;

  display: flex;
  flex-direction: column;
}

/* Header */
.header {
  width: 100%;
  padding-top: 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  position: relative;
}
.header.mobile {
  justify-content: space-around;
}

.h-logo {
  margin: 16px 0px 26px 0px;

  font-size: 64px;
  text-align: center;
}

.w-socials {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

.b-twitter {
  width: 64px;
  height: 64px;

  padding: 14px;
  box-sizing: border-box;

  background-color: var(--r4);
}

.b-discord {
  width: 64px;
  height: 64px;

  padding: 14px;
  margin-left: 16px;
  box-sizing: border-box;

  background-color: var(--r4);
}

.h-divider {
  width: 100%;
  height: 1px;
  bottom: 0;

  background-color: var(--bl);
  opacity: 0.15;

  position: absolute;
}

/* Body */
.body {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 144px;

  display: flex;
  justify-content: space-between;
}
.body.mobile {
  margin-bottom: 80px;

  flex-direction: column-reverse;
  align-items: center;
}

.body-column-1 {
  flex-basis: 60%;
  flex-shrink: 0;
  margin-right: 48px;
}
.body-column-1.mobile {
  margin-right: 0;
}

.body-column-2 {
  max-width: 400px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.body-column-2.mobile {
  margin-bottom: 24px;
}

.row-1-c-1 {
  width: 100%;

  font-size: 32px;
}
.row-1-c-1.default {
  min-height: 400px;
}

.row-1-c-2.default {
  max-height: 400px;
}

.row-2 {
  width: 100%;
  height: 90px;
  margin-top: 16px;

  display: flex;
  align-items: center;
}

.row-3 {
  margin-top: 16px;

  font-size: 32px;
  color: var(--wh);

  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Column 1 */
.body-section-highlighted {
  width: 100%;
  min-height: 92px;

  margin: 24px 0px;
  padding: 16px 24px;
  box-sizing: border-box;

  background-color: var(--r2);

  display: flex;
  align-items: center;
}

.b-how-it-works {
  width: 250px;
  height: 68px;
  font-size: 32px;

  background-color: var(--r4);

  display: flex;
  align-items: center;
}
.b-how-it-works.mobile {
  width: 100%;
}

.i-how-it-works-arrow {
  margin-left: 20px;
}

/* Column 2 */
.example-images {
  width: 100%;
}

.b-mint {
  width: 100%;
  height: 100%;

  color: black;
  background-color: var(--g3);

  font-size: 44px;
}

.b-mint-wrong-network {
  color: var(--wh);
  background-color: var(--r5);
  pointer-events: none;
}

.b-mint-sale-inactive {
  font-size: 32px;
  color: var(--wh);
  background-color: var(--r5);
  pointer-events: none;
}

</style>
